import { useState, useRef, useEffect} from 'react';
import './App.css';

function App() {
  const [stage, setStage] = useState(0);
  const audioRef = useRef(null);

  const handleStart = () => {
    setStage(1); // Move to the first text when button is clicked
    audioRef.current.play(); // Start playing audio when the user clicks 'Turn the lights on'
  };

  useEffect(() => {
    if (stage > 0 && stage < 3) {
      const timer = setTimeout(() => {
        setStage((currentStage) => currentStage + 1);
      }, 2400); // Transition every 3 seconds
      return () => clearTimeout(timer);
    }
  }, [stage]);

  return (
    <div className="App">
      {stage === 0 ? (
              <div className="getStarted">
              <div className="startButton" onClick={handleStart}> Turn the lights on</div>
            </div>) :
      <header className="App-header">
        {stage === 1 && <p className="text fade-in">This is <span style={{fontSize:"60px"}}>your life...</span></p>}
        {stage === 2 && <p className="text fade-in">...and its ending <span style={{fontSize:"60px"}}>one minute</span> at a time.</p>}
        {stage === 3 && (
          <a href="https://birdeye.so/?chain=solana" target="_blank" rel="noopener noreferrer" className="joinButton fade-in">
            JOIN PROJECT MAYHEM
          </a>
        )}
      </header>
      }
      <audio ref={audioRef} src="mayhem.mp3" preload="auto">
        Your browser does not support the audio tag.
      </audio>
    </div>
  );
}

export default App;
